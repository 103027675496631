import React from "react"
import {graphql, Link} from "gatsby"
import Phone from "../svg/phone-alt.svg";
import Instagram from "../svg/instagram.svg";
import Facebook from "../svg/facebook-f.svg";
import Yelp from "../svg/yelp.svg";
import logoImage from "../images/logo.png";
import Slugs from "./slugs";
import LeftArrow from "../svg/arrow-left.svg"

export default function Template({data}) {
	const { markdownRemark } = data // data.markdownRemark holds your post data
	const { frontmatter } = markdownRemark
	return (
		<>
			<div className="banner__header w-full bg-gradient-to-t from-gray-300 shadow-md">
				<div className="relative pt-3 px-4 sm:px-6 lg:px-8 h-56">
					<nav className="flex flex-col items-center justify-between sm:h-10 lg:justify-start">
						<div className="flex w-full border-solid border-b-2 border-gray-200 pb-3 lg:border-0" style={{paddingBottom: ".75rem"}}>
							<div className="flex justify-start w-full lg:hidden">
								<img src={Phone} className="h-5 w-5 mr-2 self-center" alt="Phone Icon"/>
								<a href="tel:1-818-331-1248" className="self-center text-sm font-default font-extrabold">+1-818-331-1248</a>
							</div>
							<div className="flex justify-end w-full">
								<a href="https://www.instagram.com/cadiblackconcierge/"><img src={Instagram} className="h-8 w-8 mr-3" alt="Instagram Icon"/></a>
								<a href="https://www.facebook.com/Cadiblack-Concierge-113396697046727/"><img src={Facebook} className="h-8 w-8 mr-3" alt="Facebook Icon"/></a>
								<a href="https://yelp.to/qTKq/u7fbTkvLA9"><img src={Yelp} className="h-8 w-8" alt="Yelp Icon"/></a>
							</div>
						</div>
						<div className="mt-5 flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
							<div className="flex items-center w-full">
								<a href="/" aria-label="Home" className="flex justify-center w-full">
									<span className="flex self-center text-5xl font-default font-extrabold md:mr-3">Cadiblack</span>
									<img src={logoImage} alt="" className="justify-self-end h-24 w-24"/>
									<span className="hidden sm:flex self-center text-5xl ml-3 font-default font-extrabold ">Concierge</span>
								</a>
							</div>
						</div>
						<div className="flex justify-around pt-4 w-full sm:flex md:flex md:ml-10 md:pr-4 lg:w-2/3">
							<Link
								to="/about-us"
								className="hidden font-default font-black text-gray-700 uppercase hover:text-black transition duration-150 ease-in-out sm:flex">
								About Us</Link>
							<Link
								to="/#cars"
								className="hidden font-default font-black text-gray-700 uppercase hover:text-black transition duration-150 ease-in-out sm:flex">
								Luxury Vehicles</Link>
							<Link
								to="/#services"
								className="font-default font-black text-gray-700 uppercase hover:text-black transition duration-150 ease-in-out">
								Services
							</Link>
							<Link
								to="/#reservations"
								className="font-default font-black text-gray-700 uppercase hover:text-black transition duration-150 ease-in-out">
								Reserve Now</Link>
						</div>
					</nav>
				</div>
			</div>
			<div className="page flex flex-col">
				<div className="header lg:hidden">
					<img src={Slugs[frontmatter.slug].image} alt="" className="w-full h-auto object-center md:hidden"/>
				</div>
				<Link to="/" className="ml-6 my-5 text-white w-1/12 text-center rounded  md:order-1 md:ml-10 md:mt-10">
					<img src={LeftArrow} alt="" className="w-8 h-8 opacity-10 hover:bg-gray-4500 "/>
				</Link>
				<div className="flex flex-col order-3">
					<img src={Slugs[frontmatter.slug].image} alt="" className="hidden object-center md:self-start md:px-10 md:mt-10 md:order-2 md:object-contain md:flex md:w-full"/>
					<div className="flex flex-col md:order-3 md:hidden">
						<div className="content px-6 pt-5 md:flex md:flex-col self-end">
							<h1 className="font-extrabold text-4xl pb-2 text-center lg:text-3xl lg:pt-2">{frontmatter.title}</h1>
							<h2 className="">{frontmatter.description}</h2>
						</div>
						<div className="ctaButtons px-6 pt-10 pb-20 md:order-4 md:flex  md:flex self-end">
							<a href="tel:1-818-331-1248" className="quoteButton text-white text-sm bg-black border-none rounded hover:bg-gray-800 py-3 px-5 mr-3 self-center">Free Quote</a>
							<Link to="/#reservations" className="reservationButton text-white text-sm bg-black border-none rounded hover:bg-gray-800 py-3 px-5 ml-3 self-center">Reservations</Link>
						</div>
					</div>
				</div>
				<div className="hidden order-5 flex-col md:flex md:m-12 lg:self-end">
					<h1 className="font-extrabold text-4xl pb-2 text-left lg:text-3xl lg:pt-2">{frontmatter.title}</h1>
					<h2 className="md:w-full">{frontmatter.description}</h2>
					<div className="ctaButtons pt-10 pb-20 md:flex self-start">
						<a href="tel:1-818-331-1248" className="quoteButton text-white text-sm bg-black border-none rounded hover:bg-gray-800 py-3 px-5 mr-3 self-center">Free Quote</a>
						<Link to="/#reservations" className="reservationButton text-white text-sm bg-black border-none rounded hover:bg-gray-800 py-3 px-5 ml-3 self-center">Reservations</Link>
					</div>
				</div>
			</div>
		</>

	)
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        slug
        title
        description
      }
    }
  }
`