import About from "../images/about.jpg";
import Airport from "../images/airport-large.png"
import Boat from "../images/boat-large.png"
import Hourly from "../images/hourly_car_service.jpg"
import P2P from "../images/point-to-point.jpg"
import Corp from "../images/business-phone-large.png"
import Event from "../images/long_distance.jpg"

export default {
	"/about-us": {
		slug: "/about-us",
		image: About
	},
	"/airport-car-service": {
		slug: "/airport-car-service",
		image: Airport,
	},
	"/corporate-travel-car-service": {
		slug: "/corporate-travel-car-service",
		image: Corp,
	},
	"/hourly-car-service": {
		slug: "/hourly-car-service",
		image: Hourly,
	},
	"/event-car-service": {
		slug: "/event-car-service",
		image: Event,
	},
	"/point-to-point-car-service": {
		slug: "/point-to-point-car-service",
		image: P2P,
	},
	"/seaport-car-service": {
		slug: "/seaport-car-service",
		image: Boat,
	},
};
